<template>
  <footer
    style="width:100%;height:30px;display:flex;justify-content:right;padding:2rem;padding-right:3rem"
  >
    <div>v1.69.1 28.10.2024 12:30 |</div>
    <div
      @click="deleteAllCookies"
      style="cursor: pointer; margin-left: .5rem;text-decoration: underline;"
    >
      cookies
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    deleteAllCookies() {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      document.location.reload(true);
    },
  },
};
</script>
